import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ApiError,
  Button,
  CondensedLarge,
  CondensedSmall,
  CookieUtil,
  DetectBrowserUtil,
  Flashy,
  Form,
  Gutter,
  Input,
  ToastUtil,
  UnauthorizedError,
  BadRequestError,
  NotFoundError,
  Grid,
  GridRow,
  GridColumn,
  SmallHeader,
  Paragraph,
  AxiomLogo,
} from '@axiom/ui';
import {
  MfaVerification,
  MfaPhoneNumberSchema,
  MfaVerificationSchema,
} from '@axiom/validation';

import sideImageUrl from '../public/svg/fingerprint.svg?url';
import { WindowUtil } from '../utils/WindowUtil';
import { ClientLoginTwoColumnLayout } from '../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { LoginContext } from '../components/LoginContext/LoginContext';
import { UsersApi } from '../api/auth-users-api';
import { AuthApi } from '../api/auth-api';
import { AuthResetsApi } from '../api/auth-resets-api';
import { EnvUtil } from '../utils/env-util';

const { getUrlWithRelayState } = WindowUtil;

export const TwoFactorAuthConfirmPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginState, isInvalidMfaState } = useContext(LoginContext);
  const [isResendShown, setIsResendShown] = useState(false);
  const [timeoutCommand, setTimeoutCommand] = useState(Date.now());

  useEffect(() => {
    if (isInvalidMfaState) {
      navigate(
        getUrlWithRelayState('/', {
          location,
        })
      );
    }
  }, [location]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsResendShown(true);
    }, 120000);

    return () => clearTimeout(timeoutId);
  }, [timeoutCommand]);

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={sideImageUrl}>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="8px">
              <SmallHeader>
                Enter your two-factor authentication code
              </SmallHeader>
            </Gutter>
            <Gutter bottom="24px">
              <Paragraph>
                We have sent a temporary code to your phone number. Enter the
                code below to verify that it's you. This code will expire after
                2 minutes.
              </Paragraph>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Form
              name="TWO_FACTOR_AUTH_CONFIRM_FORM"
              schema={MfaVerificationSchema}
              onSubmit={async (formData: MfaVerification, actions) => {
                try {
                  if (loginState.phoneNumber) {
                    const { id } = CookieUtil.decodeUserCookie();
                    await UsersApi.createMfaVerification(
                      id,
                      MfaVerificationSchema.parse(formData)
                    );
                  } else {
                    const { data: jwt } = loginState.resetPasswordToken
                      ? await AuthResetsApi.updatePassword(
                          loginState.resetPasswordToken,
                          {
                            password: loginState.password,
                            mfaCode: formData.mfaCode,
                          }
                        )
                      : await AuthApi.createAuth({
                          email: loginState.email,
                          password: loginState.password,
                          mfaCode: formData.mfaCode,
                        });

                    CookieUtil.setUserCookie(jwt, {
                      domain: EnvUtil.cookieDomain,
                      isSafari: DetectBrowserUtil.isSafari(),
                    });
                  }

                  navigate(getUrlWithRelayState(`/sso`, { location }));
                } catch (error) {
                  if (error instanceof ApiError) {
                    if (
                      error instanceof UnauthorizedError ||
                      error instanceof BadRequestError
                    ) {
                      await actions.setFieldValue('mfaCode', null);
                      actions.setFieldError(
                        'mfaCode',
                        'Invalid Code. Please request another one below.'
                      );
                    } else if (error instanceof NotFoundError) {
                      await actions.setFieldValue('mfaCode', null);
                      actions.setFieldError(
                        'mfaCode',
                        'Code Expired. Please request another one below.'
                      );
                    }
                    setIsResendShown(true);
                    setTimeoutCommand(Date.now());
                  } else {
                    throw error;
                  }
                }
              }}
            >
              {({ fireSubmit, setValues }) => {
                return (
                  <>
                    <Gutter bottom="16px">
                      <Input name="mfaCode" label="Enter the code" />
                    </Gutter>
                    {isResendShown && (
                      <Gutter bottom="16px">
                        <Flashy color="brandAxiomOrange">
                          <a
                            data-test="2FA_RESEND_CODE"
                            onClick={async () => {
                              if (loginState.phoneNumber) {
                                const { id } = CookieUtil.decodeUserCookie();
                                await UsersApi.updateMfaPhoneNumber(
                                  id,
                                  MfaPhoneNumberSchema.parse({
                                    countryCode: loginState.countryCode,
                                    phoneNumber: loginState.phoneNumber,
                                  })
                                );
                              } else if (loginState.resetPasswordToken) {
                                await AuthResetsApi.createMfaCode(
                                  loginState.resetPasswordToken
                                );
                              } else {
                                await AuthApi.createAuth({
                                  email: loginState.email,
                                  password: loginState.password,
                                  mfaCode: null,
                                });
                              }

                              setIsResendShown(false);
                              ToastUtil.add({
                                name: 'VERIFICATION_CODE_RESEND',
                                type: 'info',
                                children: (
                                  <CondensedLarge>Code Sent</CondensedLarge>
                                ),
                              });
                              setTimeoutCommand(Date.now());
                              await setValues({ mfaCode: null });
                            }}
                          >
                            <CondensedSmall>Resend code</CondensedSmall>
                          </a>
                        </Flashy>
                      </Gutter>
                    )}
                    <Button fluid onClick={fireSubmit} name="sign-in-button">
                      Sign In
                    </Button>
                  </>
                );
              }}
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
    </ClientLoginTwoColumnLayout>
  );
};
