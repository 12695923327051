import { z } from 'zod';
import { Api } from '@axiom/ui';
import { SchemaPassword } from '@axiom/types';

import { EnvUtil } from '../utils/env-util';

export const UpdatePasswordRequestSchema = z.object({
  password: SchemaPassword,
  mfaCode: z.string().nullable(),
});
export const ReadTokenResponseSchema = z.object({
  mfaCodeRequired: z.boolean(),
});
export const CreateMfaCodeResponseSchema = z.object({
  mfaCodeCreated: z.boolean(),
});
export type UpdatePasswordRequest = z.infer<typeof UpdatePasswordRequestSchema>;
export type ReadTokenResponse = z.infer<typeof ReadTokenResponseSchema>;
export type CreateMfaCodeResponse = z.infer<typeof CreateMfaCodeResponseSchema>;

class AuthResetsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.envoyApiUrl,
    });
  }

  createForgotPassword(body: { email: string; RelayState?: string }) {
    return super.write<{ data: never }>({
      endpoint: `/auth/resets`,
      method: 'POST',
      body,
    });
  }

  readToken(token: string) {
    return super.read<{ data: ReadTokenResponse }>({
      endpoint: `/auth/resets/${token}`,
      method: 'GET',
    });
  }

  createMfaCode(token: string) {
    return super.write<{ data: CreateMfaCodeResponse }>({
      endpoint: `/auth/resets/${token}`,
      method: 'POST',
    });
  }

  updatePassword(token: string, body: UpdatePasswordRequest) {
    return super.write<{ data: string }>({
      endpoint: `/auth/resets/${token}`,
      method: 'PATCH',
      body,
    });
  }
}

export const AuthResetsApi = new AuthResetsApiClass();
