import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, useApiWithErrors } from '@axiom/ui';

import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import { ExpiredLink } from '../ExpiredLink/ExpiredLink';
import { ClientResetPassword } from '../ClientResetPassword/ClientResetPassword';
import { AuthResetsApi } from '../../api/auth-resets-api';

export const SetPassword = () => {
  const { token, providedEmail } = useParams();
  const [response] = useApiWithErrors(
    token ? AuthResetsApi.readToken(token) : undefined
  );
  const tokenIsValid = !(response instanceof ApiError);

  return tokenIsValid && providedEmail ? (
    <ClientResetPassword
      token={token}
      email={providedEmail}
      mfaCodeRequired={response?.data?.mfaCodeRequired}
    />
  ) : (
    <DefaultLayout>
      <ExpiredLink />
    </DefaultLayout>
  );
};
