import Ajv from 'ajv';
import { SchemaBadges, ZodArrayToEnum } from '@axiom/types';
import { z } from 'zod';
import { CandidatesConst } from '@axiom/const';

import { CandidateSchema } from './candidate';
import { axiomValidationOptions } from './options';
import { BarredLocationSchema } from './barred-locations';
import { EducationSchema } from './education';
import { ExperienceSchema } from './experience';
import { PracticeAreaSchema } from './practice-area';

export const AfcCandidateSearchAvailabilityStatuses = Object.keys(
  CandidatesConst.AvailabilityStatuses
) as Array<keyof typeof CandidatesConst.AvailabilityStatuses>;

export const AfcCandidateSearchSchema = CandidateSchema.pick({
  addressCity: true,
  addressCountry: true,
  addressCountryCode: true, // Bad Prop APCORE-2591
  addressState: true,
  calculatedDisplayName: true,
  candidateHeader: true,
  currencyType: true,
  displayHighHourlyRate: true,
  displayLowHourlyRate: true,
  hasRate: true,
  hoursPerDay: true,
  id: true,
  industries: true,
  isAvailableToConsult: true,
  isSpotlightedLawyer: true,
  isViewOnly: true,
  lawDegreeSchools: true,
  lawFirms: true,
  occupationType: true,
  practiceArea: true,
  practiceAreaId: true, // Bad Prop APCORE-2591
  profileImageKey: true,
  profileImageName: true,
  pronouns: true,
  candidateSummary: true,
  publicRefId: true,
  searchableByClient: true,
  weeklyAvailability: true,
  yearsOfExperience: true,
}).extend({
  availabilityStatus: z
    .enum(ZodArrayToEnum(AfcCandidateSearchAvailabilityStatuses))
    .nullable(),
  axiomExperienceCount: z.number().nullable(),
  badges: SchemaBadges,
  barredLocations: z.array(
    BarredLocationSchema.pick({
      id: true,
      name: true,
    })
  ),
  // displayHighHourlyRate: z.number().nullable(), // Uncomment APCORE-2591
  // displayLowHourlyRate: z.number().nullable(), // Uncomment
  experiences: z.array(
    ExperienceSchema.pick({
      client: true,
      clientDomain: true,
    })
  ),
  // industries: z.array(  // Uncomment APCORE-2591
  //   z.object({
  //     industryValue: z.string(),
  //     yearsOfExperience: z.number(),
  //   })
  // ),
  lawDegreeSchools: z.array(
    EducationSchema.pick({
      degree: true,
      institution: true,
      yearAwarded: true,
    })
  ),
  practiceArea: PracticeAreaSchema.pick({
    id: true,
    name: true,
  }),
  primaryFocusArea: z.string(),
  primaryPracticeArea: z.string(),
});

export type AfcCandidateSearch = z.infer<typeof AfcCandidateSearchSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const idOrPublicRefIdValidation = {
  anyOf: [
    {
      type: 'string',
      format: 'uuid',
    },
    {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
  ],
};

export const idOrPublicRefIdValidator = ajv.compile(idOrPublicRefIdValidation);
