import React from 'react';

import { colors, shadows } from '../../../theme/axiom-theme';
import { AttrsHelper, cssValue } from '../../../sb-helpers/attrs-helper';

export const LayoutItem = ({
  background,
  border,
  borderLeft,
  borderRight,
  borderTop,
  borderBottom,
  borderRadius,
  bottomGutter,
  boxShadow,
  children,
  className,
  fluid,
  horizontalGutter,
  hug,
  leftGutter,
  name,
  position = 'left',
  rightGutter,
  topGutter,
  verticalGutter,
  stretched,
}: {
  background?: keyof typeof colors | CssBackgroundImage;
  border?: cssValue; // TODO APCORE-1551 Make this work: `${string}px ${string} ${keyof typeof colors}`
  borderLeft?: cssValue;
  borderRight?: cssValue;
  borderTop?: cssValue;
  borderBottom?: cssValue;
  borderRadius?: cssValue;
  bottomGutter?: cssValue;
  boxShadow?: keyof typeof shadows;
  children?: React.ReactNode;
  className?: string;
  fluid?: boolean;
  horizontalGutter?: cssValue;
  hug?: boolean;
  leftGutter?: cssValue;
  name?: string;
  position?: 'left' | 'right' | 'center';
  rightGutter?: cssValue;
  topGutter?: cssValue;
  verticalGutter?: cssValue;
  stretched?: boolean;
}) => {
  const borderL = AttrsHelper.sanitizeCssValue(borderLeft || border);
  const borderR = AttrsHelper.sanitizeCssValue(borderRight || border);
  const borderT = AttrsHelper.sanitizeCssValue(borderTop || border);
  const borderB = AttrsHelper.sanitizeCssValue(borderBottom || border);
  const styles = {
    backgroundColor:
      typeof background === 'string' ? colors[background] : undefined,
    ...(background && typeof background === 'object'
      ? {
          backgroundImage: `url("${background.image}")`,
          backgroundAttachment: background.attachment,
          backgroundClip: background.clip,
          backgroundColor: background.color,
          backgroundPosition: background.position || 'center right',
          backgroundRepeat: background.repeat || 'no-repeat',
        }
      : {}),
    borderLeft: borderL
      ? AttrsHelper.replaceColorNameWithHex(borderL)
      : undefined,
    borderRight: borderR
      ? AttrsHelper.replaceColorNameWithHex(borderR)
      : undefined,
    borderTop: borderT
      ? AttrsHelper.replaceColorNameWithHex(borderT)
      : undefined,
    borderBottom: borderB
      ? AttrsHelper.replaceColorNameWithHex(borderB)
      : undefined,
    boxShadow: boxShadow && shadows[boxShadow],
    borderRadius: AttrsHelper.sanitizeCssValue(borderRadius),
    paddingBottom: AttrsHelper.sanitizeCssValue(bottomGutter || verticalGutter),
    paddingLeft: AttrsHelper.sanitizeCssValue(leftGutter || horizontalGutter),
    paddingRight: AttrsHelper.sanitizeCssValue(rightGutter || horizontalGutter),
    paddingTop: AttrsHelper.sanitizeCssValue(topGutter || verticalGutter),
    textAlign: position,
  };
  return (
    <div
      className={AttrsHelper.formatClassname(
        'layout-item',
        fluid && 'fluid-item',
        hug && 'hug-item',
        stretched && 'layout-stretched',
        className
      )}
      data-test={name}
      style={styles}
    >
      {children}
    </div>
  );
};
