import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';
import { CountryCodesConst } from '@axiom/const';

import { OpportunitySchema } from './opportunity';

const CandidateWorkFeedCountryCodes = Object.keys(
  CountryCodesConst.CountryCodesAbbreviations
) as Array<keyof typeof CountryCodesConst.CountryCodesAbbreviations>;

export const CandidateWorkFeedOpportunitySchema = OpportunitySchema.partial()
  .pick({
    allowInterest: true,
    accountName: true,
    closedDate: true,
    description: true,
    directEligible: true,
    id: true,
    isExcludedFromFeed: true,
    jobName: true,
    languages: true,
    locationCityStateCountry: true,
    madeAvailableToFeedAt: true,
    madeAvailableToFeedNotificationAt: true,
    practiceAreaId: true,
    stage: true,
  })
  .extend({
    id: z.string().uuid(),
    countryCode: z
      .enum(ZodArrayToEnum(CandidateWorkFeedCountryCodes))
      .nullable(),
  });

export type CandidateWorkFeedOpportunity = z.infer<
  typeof CandidateWorkFeedOpportunitySchema
>;
