import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';
import { SchemaTypes } from '@axiom/types';

import { FormField } from '../FormField/FormField';
import { IconName } from '../../element/Icon/icon-types';

import {
  RawDropdown,
  RawDropdownProps,
  RawDropdownValueType,
} from './RawDropdown';

export type DropdownProps<AsyncConnectorResult> = {
  description?: string;
  direction?: RawDropdownProps<AsyncConnectorResult>['direction'];
  displayKey: RawDropdownProps<AsyncConnectorResult>['displayKey'];
  disabled?: RawDropdownProps<AsyncConnectorResult>['disabled'];
  icon?: IconName;
  id?: RawDropdownProps<AsyncConnectorResult>['id'];
  label?: React.ReactNode;
  name: RawDropdownProps<AsyncConnectorResult>['name'];
  onAddItem?: (value?: RawDropdownValueType) => void;
  onBlur?: (value?: RawDropdownValueType) => void;
  onChange?: (
    value?: RawDropdownValueType,
    e?: React.SyntheticEvent<HTMLElement, Event>,
    valueOption?: Record<string, unknown> | Record<string, unknown>[]
  ) => void;
  onFocus?: (value?: RawDropdownValueType) => void;
  onType?: (value?: RawDropdownValueType) => void;
  options: RawDropdownProps<AsyncConnectorResult>['options'];
  placeholder?: RawDropdownProps<AsyncConnectorResult>['placeholder'];
  Tooltip?: React.ReactNode;
  valueKey: RawDropdownProps<AsyncConnectorResult>['valueKey'];
};

export const Dropdown = <AsyncConnectorResult,>({
  description,
  direction,
  displayKey,
  disabled,
  icon,
  id,
  label,
  name,
  onAddItem,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  onType = () => {},
  options,
  placeholder,
  Tooltip,
  valueKey,
}: DropdownProps<AsyncConnectorResult>) => {
  id = id || name;
  return (
    <FormField
      {...{
        description,
        disabled,
        icon,
        id,
        name,
        label,
        onClear: (v: RawDropdownValueType) => onChange(v),
        Tooltip,
      }}
      renderField={({
        value,
        onBlur: formOnBlur,
        setFieldValue,
        invalid,
        schemaProperty,
      }: {
        value: RawDropdownProps<AsyncConnectorResult>['value'];
      } & FormFieldProps) => (
        <RawDropdown
          allowAdditions={!!onAddItem}
          direction={direction}
          displayKey={displayKey}
          disabled={disabled}
          hideIcon={!!icon}
          id={id}
          invalid={invalid}
          multiple={schemaProperty.type === SchemaTypes.ZodArray}
          name={name}
          onAddItem={v => {
            onAddItem?.(v);
          }}
          onBlur={e => {
            onBlur(e.target.value);
            formOnBlur({ ...e, target: { ...e.target, name } });
          }}
          onChange={(v, e, valueRecord) => {
            onChange(v, e, valueRecord);
            setFieldValue(name, v);
          }}
          onFocus={() => {
            onFocus();
          }}
          onType={onType}
          options={options}
          placeholder={placeholder}
          value={value}
          valueKey={valueKey}
        />
      )}
    />
  );
};
