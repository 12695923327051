import { z } from 'zod';

export const TaxonomyIndustrySchema = z.object({
  displayValue: z.string(),
});

export type TaxonomyIndustry = z.infer<typeof TaxonomyIndustrySchema>;
export const TaxonomyLegalTechCategorySchema = z.object({
  displayName: z.string(),
});
export const TaxonomyLegalTechSkillSchema = z.object({
  displayName: z.string(),
  legalTechSkillCategory: z.string(),
});
export const TaxonomyGeneralSkillSchema = z.object({
  displayName: z.string(),
});
export const TaxonomyLegalSkillSchema = z.object({
  skillGroup: z.string(),
  talentName: z.string(),
  alpEligible: z.boolean(),
  fullName: z.string(),
  shortName: z.string(),
});
export type TaxonomyLegalSkill = z.infer<typeof TaxonomyLegalSkillSchema>;

export const TaxonomyPracticeAreaSchema = z.object({
  fullName: z.string(),
  talentName: z.string(),
  shortName: z.string(),
  alpEligible: z.boolean(),
});
export const TaxonomyFocusAreaSchema = z.object({
  alpEligible: z.boolean(),
  fullName: z.string(),
  practiceArea: z.string(),
  shortName: z.string(),
  standaloneDisplayName: z.string(),
  talentName: z.string(),
});
export type TaxonomyFocusArea = z.infer<typeof TaxonomyFocusAreaSchema>;

export const TaxonomySkillGroupSchema = z.object({
  clientQuestion: z.string(),
  fullName: z.string(),
  talentQuestion: z.string(),
  focusArea: z.string(),
  alpEligible: z.boolean(),
});

export type TaxonomySkillGroup = z.infer<typeof TaxonomySkillGroupSchema>;

export const TaxonomySchema = z.object({
  generalSkills: z.record(z.string(), TaxonomyGeneralSkillSchema),
  industries: z.record(z.string(), TaxonomyIndustrySchema),
  legalSkills: z.record(z.string(), TaxonomyLegalSkillSchema),
  legalTechSkillCategories: z.record(
    z.string(),
    TaxonomyLegalTechCategorySchema
  ),
  legalTechSkills: z.record(z.string(), TaxonomyLegalTechSkillSchema),
  practiceAreas: z.record(z.string(), TaxonomyPracticeAreaSchema),
  focusAreas: z.record(z.string(), TaxonomyFocusAreaSchema),
  skillGroups: z.record(z.string(), TaxonomySkillGroupSchema),
});

export type Taxonomy = z.infer<typeof TaxonomySchema>;

type TaxonomyType = z.infer<typeof TaxonomySchema>;
export type TaxonomyFocusAreaEnum = keyof TaxonomyType['focusAreas'];
export type TaxonomyGeneralSkillEnum = keyof TaxonomyType['generalSkills'];
export type TaxonomyIndustryEnum = keyof TaxonomyType['industries'];
export type TaxonomyLegalSkillEnum = keyof TaxonomyType['legalSkills'];
export type TaxonomyLegalTechSkillCategoryEnum =
  keyof TaxonomyType['legalTechSkillCategories'];
export type TaxonomyLegalTechSkillEnum = keyof TaxonomyType['legalTechSkills'];
export type TaxonomyPracticeAreaEnum = keyof TaxonomyType['practiceAreas'];
export type TaxonomySkillGroupEnum = keyof TaxonomyType['skillGroups'];
