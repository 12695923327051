const storage = window.localStorage;

/**
 * All the try/catch are for the very rare
 * browsers that don't do localStorage
 * */

type StorageValue = Record<string, unknown> | string | boolean | null;

export const LocalStorage = (id: string, defaultValue: StorageValue) => ({
  get() {
    try {
      const raw = storage.getItem(id);
      return raw ? JSON.parse(raw) : defaultValue;
    } catch {
      return defaultValue;
    }
  },

  set(value: StorageValue = null) {
    if (value === null || value === '') {
      this.clear();
    } else {
      try {
        value = JSON.stringify(value);
        storage.setItem(id, value);
      } catch {
        // silently fail
      }
    }
  },

  clear() {
    try {
      storage.removeItem(id);
    } catch {
      // silently fail
    }
  },
});
