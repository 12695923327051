import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaDate, SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';
import {
  Opportunity,
  TimeCommitment,
  PositionsConst,
  EngagementLengthConst,
  CountryCodesConst,
  CandidateOpportunitiesConst,
  WorkFeedConst,
} from '@axiom/const';

import { axiomValidationOptions } from './options';
import { AccountSchema } from './account';
import { PositionSchema } from './position';
import { PracticeAreaSchema } from './practice-area';
import { ZodCandidateOpportunityCandidateStatuses } from './candidate-opportunities';
import { CandidateWorkFeedOpportunitySchema } from './candidate-work-feed-opportunity';

const { Phases } = WorkFeedConst;
const { CalculatedTalentTypes, Worksites } = PositionsConst;
const { EngagementLengths } = EngagementLengthConst;

const PhasesValues = Object.values(Phases);

const EngagementLengthValues = Object.values(EngagementLengths);

const CalculatedTalentTypeValues = Object.values(CalculatedTalentTypes);

const TimeCommitmentValues = Object.values(TimeCommitment.HoursPerWeek);

const WorksitesValues = Object.values(Worksites);

export const CandidateWorkFeedCountryCodes = Object.keys(
  CountryCodesConst.CountryCodesAbbreviations
) as Array<keyof typeof CountryCodesConst.CountryCodesAbbreviations>;

export const CandidateWorkFeedItemSchema = z.object({
  account: AccountSchema.partial().pick({
    calculatedName: true,
    website: true,
  }),
  addedToPoolAt: SchemaTimestamp.nullable(),
  candidateIsInterested: z.boolean().default(false),
  candidateStatus: ZodCandidateOpportunityCandidateStatuses.nullable(),
  id: z.string().uuid(),
  isNotAvailableOnCandidateFeed: z.boolean().default(false),
  isSaved: z.boolean().default(false),
  opportunity: CandidateWorkFeedOpportunitySchema,
  phase: z.enum(ZodArrayToEnum(PhasesValues)),
  phaseInterviewingDate: SchemaDate.nullable(),
  phaseSubmittedDate: SchemaDate.nullable(),
  position: PositionSchema.partial().pick({
    billingHoursPerWeek: true,
    billingUnitsPerWeek: true,
    calculatedTalentType: true,
    description: true,
    endDate: true,
    estimatedEngagementTotalMonths: true,
    id: true,
    name: true,
    role: true,
    startDate: true,
    weeklyTimeUnit: true,
    worksite: true,
  }),
  practiceArea: PracticeAreaSchema.partial().pick({ name: true }).nullish(),
  rejectionLossCode: z
    .enum(
      ZodArrayToEnum(
        CandidateOpportunitiesConst.CandidateRejectionLossCodesValues
      )
    )
    .nullable(),
  rejectionLossCodeCustomMessage: z.string().nullable(),
  runningCandidateInterestedCount: z.number().nonnegative().int().default(0),
  runningCandidateInterviewingCount: z.number().nonnegative().int().default(0),
  runningCandidateSubmittedCount: z.number().nonnegative().int().default(0),
});

export type CandidateWorkFeedItem = z.infer<typeof CandidateWorkFeedItemSchema>;

export const CandidateWorkFeedFormFilterSchema = z.object({
  countries: z
    .array(z.enum(ZodArrayToEnum(CandidateWorkFeedCountryCodes)))
    .nullish(),
  engagementLength: z
    .array(z.enum(ZodArrayToEnum(EngagementLengthValues)))
    .nullish(),
  industries: z.array(z.string()).nullish(),
  opportunityPhases: z.array(z.enum(ZodArrayToEnum(PhasesValues))).nullish(),
  practiceAreaId: z.array(z.string()).nullish(),
  talentTypes: z
    .array(z.enum(ZodArrayToEnum(CalculatedTalentTypeValues)))
    .nullish(),
  timeCommitment: z
    .array(z.enum(ZodArrayToEnum(TimeCommitmentValues)))
    .nullish(),
  worksites: z.array(z.enum(ZodArrayToEnum(WorksitesValues))).nullish(),
});

export type CandidateWorkFeedFormFilter = z.infer<
  typeof CandidateWorkFeedFormFilterSchema
>;

export const CandidateWorkFeedFilterSchema = z.object({
  filters: CandidateWorkFeedFormFilterSchema,
  page: z.number().nonnegative().int().default(1),
  pageSize: z.number().nonnegative().int().default(10),
});

export type CandidateWorkFeedFilter = z.infer<
  typeof CandidateWorkFeedFilterSchema
>;

export const CandidateWorkFeedSearchSchema = z.object({
  meta: z.object({
    currentPage: z.number().nonnegative().int(),
    pageCount: z.number().nonnegative().int(),
    resultCount: z.number().nonnegative().int(),
    aggregations: z.number().nonnegative().int().nullable(),
  }),
  data: z.array(CandidateWorkFeedItemSchema).nullish(),
});

export type CandidateWorkFeedSearch = z.infer<
  typeof CandidateWorkFeedSearchSchema
>;

export const CandidateWorkFeedInterestedCountSchema = z.object({
  interestedCount: z.number().nonnegative().int(),
});

export type CandidateWorkFeedInterestedCount = z.infer<
  typeof CandidateWorkFeedInterestedCountSchema
>;

export const CandidateWorkFeedStatisticsSchema = z.object({
  candidateId: z.string().uuid(),
  roundedTotal: z.number().nonnegative().int(),
});

export type CandidateWorkFeedStatistics = z.infer<
  typeof CandidateWorkFeedStatisticsSchema
>;

export const CandidateWorkFeedHideSchema = z.object({
  opportunityId: z.string().uuid(),
});

export type CandidatesHiddenOpportunity = z.infer<
  typeof CandidateWorkFeedHideSchema
>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const ajvNoCoerce = new Ajv({
  ...axiomValidationOptions(),
});

// Validations
const candidateWorkFeedPageSizeValidation = {
  type: 'number',
  maximum: 100,
  minimum: 1,
  name: 'pageSize',
};

const candidateWorkFeedFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    practiceAreaId: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
    industries: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
    timeCommitment: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(TimeCommitment),
      },
    },
    worksites: {
      type: 'array',
      items: { type: 'string', enum: Object.values(PositionsConst.Worksites) },
    },
    engagementLength: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(EngagementLengthConst),
      },
    },
    countries: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.keys(CandidateWorkFeedCountryCodes),
      },
    },
    talentTypes: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(PositionsConst.CalculatedTalentTypes),
      },
    },
  },
};

// Validators
export const candidateWorkFeedPageSizeValidator = ajvNoCoerce.compile(
  candidateWorkFeedPageSizeValidation
);

export const candidateWorkFeedFilterValidator = ajv.compile({
  ...candidateWorkFeedFilterValidation,
});

export const candidateWorkFeedSortTermValidator = ajvNoCoerce.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {
      type: 'string',
      enum: [`opportunity.${Opportunity.CREATED_AT}`],
    },
  },
});
