import React from 'react';
import kebabCase from 'lodash/kebabCase';

import { AttrsHelper, cssValue } from '../../../sb-helpers/attrs-helper';
import { AxiomTheme } from '../../../theme/axiom-theme';
import { ViewportName } from '../../../configs/responsive-config';

const basePxSize = Number(AxiomTheme.baseFontSize.replace('px', ''));

const pxToRems = (value: cssValue = '') =>
  AttrsHelper.sanitizeCssValue(
    typeof value === 'string' && value?.endsWith('rem')
      ? `${Number(value.replace('rem', '')) * basePxSize}px`
      : value
  );

export type GutterProps = {
  as?: 'p' | 'div' | 'span';
  children?: React.ReactNode;
  className?: string;
  gutterType?: 'margin' | 'padding';
  only?: ViewportName | string;
  name?: string;
  top?: cssValue;
  bottom?: cssValue;
  left?: cssValue;
  right?: cssValue;
  vertical?: cssValue;
  horizontal?: cssValue;
};
export const Gutter = ({
  as = 'div',
  children,
  className,
  gutterType = 'padding',
  name,
  only,
  top,
  bottom,
  left,
  right,
  vertical,
  horizontal,
}: GutterProps) => {
  const viewportClasses = typeof only === 'string' ? only.split(' ') : only;
  const styles = {
    [`${gutterType}Top`]: pxToRems(vertical || top),
    [`${gutterType}Bottom`]: pxToRems(vertical || bottom),
    [`${gutterType}Left`]: pxToRems(horizontal || left),
    [`${gutterType}Right`]: pxToRems(horizontal || right),
  };
  if (as === 'span') {
    styles.display = 'inline-block';
  }

  return React.createElement(
    as,
    {
      style: styles,
      className: AttrsHelper.formatClassname(
        className,
        `${
          viewportClasses
            ? viewportClasses
                .map(viewport => `visible-${kebabCase(viewport)}`)
                .join(' ')
            : ''
        }`
      ),
      'data-test': name,
    },
    children
  );
};
