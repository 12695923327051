import Ajv from 'ajv';
import { z } from 'zod';
import { ProfileCompletionConst } from '@axiom/const';
import { SchemaDate, SchemaLocation, SchemaTimestamp } from '@axiom/types';

import { OpportunitySchema } from './opportunity';
import { axiomValidationOptions } from './options';
import { PositionSchema } from './position';

const ajv = new Ajv(axiomValidationOptions());

const BaseExperienceTaxonomySchema = z.object({
  id: z.string().uuid(),
  experienceId: z.string().uuid(),
  practiceArea: z.string(),
  focusArea: z.string(),
  dsSuggestion: z.boolean(),
});

export const ExperienceTaxonomyFocusAreaSchema =
  BaseExperienceTaxonomySchema.extend({
    skillGroup: z.null(),
    legalSkills: z.null(),
  });
export type ExperienceTaxonomyFocusArea = z.infer<
  typeof ExperienceTaxonomyFocusAreaSchema
>;

export const ExperienceTaxonomyLegalSkillSchema =
  BaseExperienceTaxonomySchema.extend({
    skillGroup: z.string().nullable(),
    legalSkills: z.array(z.string()).nullable(),
  });

export type ExperienceTaxonomyLegalSkill = z.infer<
  typeof ExperienceTaxonomyLegalSkillSchema
>;

export const ExperienceTaxonomySchema = z.union([
  ExperienceTaxonomyFocusAreaSchema,
  ExperienceTaxonomyLegalSkillSchema,
]);

export type ExperienceTaxonomy = z.infer<typeof ExperienceTaxonomySchema>;

export const ExperienceFocusAreaOrderPreferenceSchema = z.object({
  experienceId: z.string().uuid(),
  focusAreas: z.array(z.string()).min(1),
});

export type ExperienceFocusAreaOrderPreference = z.infer<
  typeof ExperienceFocusAreaOrderPreferenceSchema
>;

// Edit Experience form needs this to be not nullable
export const ExperienceDescriptionType = z
  .string()
  .max(ProfileCompletionConst.maximumAcceptableExperienceDescriptionLength);

export const ExperienceSchema = z
  .object({
    candidateId: z.string().uuid(),
    client: z.string().max(255), // being deprecated
    calculatedDisplayName: z.string().max(255).nullable(),
    clientDomain: z.string().max(255).nullish(),
    description: ExperienceDescriptionType.nullable(),
    endDate: SchemaDate.nullish(),
    experiencesFocusAreaOrderPreferences: z
      .array(ExperienceFocusAreaOrderPreferenceSchema)
      .min(1),
    experienceTaxonomy: z.array(ExperienceTaxonomySchema).min(1),
    externalOpportunityName: z.string().max(255),
    generalSkills: z.array(z.string()).nullable(),
    id: z.string().uuid(),
    industryIsOverridden: z.boolean().default(false),
    industryValue: z.string().nullable(),
    isAxiom: z.boolean().default(false),
    isConfidential: z.boolean(),
    opportunity: OpportunitySchema.omit({ positions: true })
      .extend({
        position: PositionSchema.nullish(),
      })
      .nullable(),
    opportunityId: z.string().uuid().nullable(),
    practiceAreaId: z.string().uuid().nullish(),
    suggestedFocusAreasRetrievedAt: SchemaTimestamp,
    suggestedLegalSkillsRetrievedAt: SchemaTimestamp,
    startDate: SchemaDate,
    suggestMergeDismissedAt: SchemaTimestamp.nullable(),
    suggestAxiomEngageDismissedAt: SchemaTimestamp.nullable(),
  })
  .merge(SchemaLocation);

export type Experience = z.infer<typeof ExperienceSchema>;

export const GroupedExperiencesSchema = ExperienceSchema.pick({
  candidateId: true,
}).merge(
  z.object({
    isAxiom: z.boolean(),
    endDate: SchemaDate,
    startDate: SchemaDate,
    experiences: z.array(ExperienceSchema),
  })
);

export type GroupedExperiences = z.infer<typeof GroupedExperiencesSchema>;

export const ExperienceEditSchema = ExperienceSchema.omit({
  opportunity: true,
  isConfidential: true,
  calculatedDisplayName: true,
  isAxiom: true,
  experiencesFocusAreaOrderPreferences: true,
  experienceTaxonomy: true,
})
  .partial({
    id: true,
    opportunityId: true,
  })
  .extend({
    generalSkills: z.array(z.string()),
    industryValue: z.string(),
  });
export type ExperienceEdit = z.infer<typeof ExperienceEditSchema>;

const experienceCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    client: {
      type: 'string',
    },
    externalOpportunityName: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    startDate: {
      type: 'string',
      format: 'date',
    },
    endDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    clientDomain: {
      type: ['string', 'null'],
    },
    locationAddressComponents: {
      type: ['object', 'null'],
      additionalProperties: false,
      properties: {
        addresses: {
          items: {
            type: 'object',
            additionalProperties: false,
            properties: {
              types: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
              long_name: {
                type: 'string',
              },
              short_name: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    locationName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    locationPlaceId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    isConfidential: {
      type: ['boolean'],
    },
    opportunityId: {
      type: 'string',
      format: 'uuid',
    },
    industryValue: {
      type: 'string',
    },
  },
};

export const createExperienceValidator = ajv.compile({
  ...experienceCommonValidation,
  required: ['client', 'startDate'],
});

const allowedExperienceUpdates = [
  { required: ['client'] },
  { required: ['description'] },
  { required: ['startDate'] },
  { required: ['endDate'] },
  { required: ['externalOpportunityName'] },
  { required: ['locationName'] },
  { required: ['locationAddressComponents'] },
  { required: ['locationPlaceId'] },
  { required: ['isConfidential'] },
  { required: ['industryValue'] },
];

export const updateExperienceValidator = ajv.compile({
  ...experienceCommonValidation,
  anyOf: [...allowedExperienceUpdates],
});
