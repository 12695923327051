import React from 'react';
import { CandidateLegalTechSkill, Taxonomy } from '@axiom/validation';

import {
  CandidateTaxonomyUtil,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';

export type LegalTechSkillsDisplayType = {
  legalTechSkills: CandidateLegalTechSkill[] | null;
  taxonomy: Taxonomy;
};

export const LegalTechSkillsDisplay = ({
  legalTechSkills,
  taxonomy,
}: LegalTechSkillsDisplayType) => {
  const formattedLegalTechSkills =
    CandidateTaxonomyUtil.getDisplayableLegalTechSkills(legalTechSkills);

  if (Object.keys(formattedLegalTechSkills).length === 0) {
    return null;
  }

  return (
    <Layout
      direction="vertical"
      verticalGutter="16px"
      name="LEGAL_TECH_SKILLS_DISPLAY"
    >
      {Object.entries(formattedLegalTechSkills).map(([category, skills]) => (
        <LayoutItem name="LEGAL_TECH_SKILL_CATEGORY" key={category}>
          <ParagraphHeader name={`LEGAL_TECH_SKILL_CATEGORY_${category}`}>
            {taxonomy.legalTechSkillCategories[category]?.displayName}
          </ParagraphHeader>
          <Paragraph name={`LEGAL_TECH_SKILLS_LIST_${category}`}>
            {skills
              .map(skill => taxonomy.legalTechSkills[skill]?.displayName)
              .join('; ')}
          </Paragraph>
        </LayoutItem>
      ))}
    </Layout>
  );
};
