import { FetchWatcher } from './endpoint-cache';

export type DataFetcherParams = {
  search?: string;
  sort: Record<string, string | null | undefined>;
  page: number;
};
type DataFetcher<AsyncConnectorResult> = ({
  sort,
  page,
}: DataFetcherParams) => FetchWatcher<AsyncConnectorResult>;

export type DataParserResponse = {
  currentPage: number;
  totalPages: number;
  data: Array<unknown> | undefined | null;
};

type DataParser<AsyncConnectorResult> = (
  response: AsyncConnectorResult
) => DataParserResponse;

export class AsyncConnector<AsyncConnectorResult> {
  private dataFetcher: DataFetcher<AsyncConnectorResult>;

  private dataParser: DataParser<AsyncConnectorResult>;

  private pageSize: number;

  private watcher?: FetchWatcher<unknown>;

  private fetchCount = 0;

  constructor({
    getData,
    parseData,
    pageSize = 20,
  }: {
    getData: DataFetcher<AsyncConnectorResult>;
    parseData: DataParser<AsyncConnectorResult>;
    pageSize?: number;
  }) {
    this.dataFetcher = getData;
    this.dataParser = parseData;
    this.pageSize = pageSize;
  }

  getData(args: DataFetcherParams) {
    this.watcher = this.dataFetcher(args);
    return (
      this.watcher.hasResolved()
        ? this.watcher.refetch(false)
        : this.watcher.toPromise()
    ).then(data => {
      this.fetchCount += 1;
      return data as AsyncConnectorResult;
    });
  }

  onInvalidate(evt: () => void) {
    return this.watcher?.listenForInvalidate(evt);
  }

  parseData(response: AsyncConnectorResult) {
    return this.dataParser(response) as DataParserResponse;
  }

  getPageSize() {
    return this.pageSize;
  }

  hasFetched() {
    return this.fetchCount > 0;
  }
}
